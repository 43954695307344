<template>
	<div class="app-container">
		<el-row :gutter="20">
			<el-col :span="12">
				<div class="headline">公司股权结构</div>
				<div id="companyPie" class="companyPie" v-if="companyStockList.length"></div>
				<Empty v-else />
			</el-col>
			<template v-if="companyStockList.filter(({ type }) => type === 1)?.length < 2">
				<div class="headline">员工持股平台份额占比</div>

				<el-col :span="12" v-if="!companyStockList.filter(({ type }) => type === 1).length">
					<!-- <div id="companyPie0" class="companyPie"></div> -->
					<Empty />
				</el-col>
				<el-col v-else :span="12">
					<div :id="`companyPie0`" class="companyPie"></div>
				</el-col>
			</template>
		</el-row>
		<template v-if="companyStockList.filter(({ type }) => type === 1)?.length > 1">
			<div class="headline">员工持股平台份额占比</div>
			<el-row :gutter="20">
				<el-col v-for="(item, inx) in companyStockList.filter(({ type }) => type === 1)" :key="item.id" :span="8">
					<div :id="`companyPie${inx}`" class="companyPieItem"></div>
				</el-col>
			</el-row>
		</template>
		<div class="headline">期权概况</div>
		<el-row>
			<el-col :span="6" v-for="val in cardItems" :key="val.title">
				<div class="card" :style="`background:${val.style.background}`">
					<div class="leftContent">
						<div>{{ val.title }}</div>
						<div>{{ val.percent }}</div>
						<div>{{ val.footer }}</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row style="margin-top: 20px">
			<el-col :span="6">
				<div class="leftCard">
					<div class="itemContent">
						<div>期权池总数</div>
						<div>{{ equitySituation?.sumPlatStockNum || 0 }}股</div>
					</div>
					<div class="itemContent">
						<div>期权池预留股数</div>
						<div>{{ equitySituation?.reserveStockNum || 0 }}股</div>
					</div>
				</div>
			</el-col>
			<el-col :span="6">
				<div id="rightGraph1"></div>
			</el-col>
			<el-col :span="6">
				<div id="rightGraph2"></div>
			</el-col>
			<el-col :span="6">
				<div id="rightGraph3"></div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import Empty from '@/views/components/components-empty';
import { Loading } from 'element-ui';
export default {
	components: { Empty },
	name: 'GeneralSituation',

	data() {
		return { companyStockList: [], equitySituation: {} };
	},
	computed: {
		pieOptions() {
			return ({ title, data, showLegend }) => ({
				title: {
					text: title,
					// subtext: '',
					left: 'center'
				},
				tooltip: {
					trigger: 'item',
					formatter: (data) => {
						const { value, ratio, name } = data.data || {};

						return `${name} <br/> ${ratio}% <br/> 股数 ${value}`;
					}
				},
				legend: {
					show: showLegend || false,
					type: 'scroll',
					orient: 'vertical',
					right: 10,
					top: 80,
					bottom: 20
				},
				series: [
					{
						// name: '',
						type: 'pie',
						// avoidLabelOverlap: false, //文字省略
						radius: '60%',
						data,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
							// label: {
							// 	show: true
							// 	// 高亮时标签的文字。
							// 	// formatter: 'This is a emphasis label.'
							// }
						}
					}
				]
			});
		},
		cardItems() {
			const {
				sumPlatStockNum,
				sumPlatStockNumRatio,
				reserveStockNum,
				reserveStockNumRatio,
				sumLockStockNumRatio,
				sumLockStockNum,
				banStockNumRatio,
				banStockNum
			} = this.equitySituation;

			return [
				{
					title: '期权池占总股权比例',
					style: { background: 'linear-gradient(to right, #3d3d44, rgba(61,61,68,0.8))' },
					percent: `${sumPlatStockNumRatio || 0}%`,
					footer: `期权池占总股数 ${sumPlatStockNum || 0}`
				},
				{
					title: '预留期权池占总股权比例',
					style: { background: 'linear-gradient(to right, #fe6e3c, rgba(254,110,60,0.8))' },
					percent: `${reserveStockNumRatio || 0}%`,
					footer: `预留期权池 ${reserveStockNum || 0}`
				},
				{
					title: '待解锁股权占期权池比例',
					style: { background: 'linear-gradient(to right, #2abffa, rgba(42,191,250,0.8))' },
					percent: `${sumLockStockNumRatio || 0}%`,
					footer: `待解锁股权 ${sumLockStockNum || 0}`
				},
				{
					title: '待行权股数占期权池的比例',
					style: { background: 'linear-gradient(to right, #32a6a4, rgba(50,166,164,0.8))' },
					percent: `${banStockNumRatio || 0}%`,
					footer: `待行权股数 ${banStockNum || 0}`
				}
			];
		}
	},

	mounted() {
		this.$nextTick(() => {
			// 公司股权
			this.getCompanyStock();
			// 概况
			this.getStockSituation();
		});
	},

	methods: {
		// 股权概况 图
		drawingGraph() {
			const { init } = this.$echarts;
			const rightGraph1 = init(document.getElementById('rightGraph1'));
			const rightGraph2 = init(document.getElementById('rightGraph2'));
			const rightGraph3 = init(document.getElementById('rightGraph3'));

			const {
				reserveStockNum,
				grantLimitStockNum,
				grantOptionStockNum,
				unEffectGrantOptionStockNum,
				effectBanGrantOptionStockNum,
				liftbanGrantOptionStockNum,
				banGrantLimitStockNum,
				liftbanGrantLimitStockNum,
				reserveStockNumRatio,
				grantLimitStockNumRatio,
				grantOptionStockNumRatio,
				unEffectGrantOptionStockNumRatio,
				effectBanGrantOptionStockNumRatio,
				liftbanGrantOptionStockNumRatio,
				banGrantLimitStockNumRatio,
				liftbanGrantLimitStockNumRatio
			} = this.equitySituation;

			rightGraph1.setOption(
				this.pieOptions({
					title: '',
					data: [
						{ value: reserveStockNum, name: '期权池预留', ratio: reserveStockNumRatio || '' },
						{ value: grantLimitStockNum, name: '已授予限制股票', ratio: grantLimitStockNumRatio || '' },
						{ value: grantOptionStockNum, name: '已授予期权股数', ratio: grantOptionStockNumRatio || '' }
					]
				})
			);
			rightGraph2.setOption(
				this.pieOptions({
					title: '期权股数',
					// showLegend: true,
					data: [
						{ value: unEffectGrantOptionStockNum, name: '未行权已授予', ratio: unEffectGrantOptionStockNumRatio },
						{ value: effectBanGrantOptionStockNum, name: '已行权未解锁已授予', ratio: effectBanGrantOptionStockNumRatio },
						{ value: liftbanGrantOptionStockNum, name: '已解锁已授予', ratio: liftbanGrantOptionStockNumRatio }
					]
				})
			);
			rightGraph3.setOption(
				this.pieOptions({
					title: '限制性股票股数',
					// showLegend: true,
					data: [
						{ value: banGrantLimitStockNum, name: '未解锁', ratio: banGrantLimitStockNumRatio },
						{ value: liftbanGrantLimitStockNum, name: '已解锁', ratio: liftbanGrantLimitStockNumRatio }
					]
				})
			);
		},
		// 公司股权结构 图
		drawingGraphCompany() {
			const { init } = this.$echarts;

			if (this.companyStockList.length === 0) {
				return;
			}

			this.companyStockList
				.filter(({ type }) => type === 1)
				.forEach((it, inx) => {
					const companyPie = init(document.getElementById(`companyPie${inx}`));

					companyPie.setOption(
						this.pieOptions({
							showLegend: true,
							title: it.name,
							data: it.childs?.map(({ name, ratio, stockNum }) => ({ ratio, name, stockNum, value: stockNum })) || []
						})
					);
				});
			const pie = init(document.getElementById('companyPie'));

			pie.setOption(
				this.pieOptions({
					showLegend: true,
					title: '',
					data: this.companyStockList?.map(({ name, ratio, stockNum }) => ({ ratio, name, stockNum, value: stockNum })) || []
				})
			);
		},
		// 公司股权结构数据
		getCompanyStock() {
			const loading = Loading.service({ target: '.app-container' });

			this.$axios.post('/v1/stock/findCompanyStockList').then((res) => {
				loading.close();
				this.companyStockList = res;
				if (res.code) {
					this.companyStockList = [];
				}
				this.$nextTick(() => {
					this.drawingGraphCompany();
				});
			});
		},
		// 查询公司股权概况
		getStockSituation() {
			this.$axios.post('/v1/stock/findStockSituation').then((res) => {
				this.equitySituation = res;
				this.$nextTick(() => {
					this.drawingGraph();
				});
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.headline {
	font-weight: bold;
	font-size: 20px;
	height: 40px;
	line-height: 40px;
	margin-bottom: 20px;
	&::before {
		display: inline-block;
		width: 8px;
		height: 20px;
		transform: translateY(1px);
		background: #498df0;
		content: ' ';
		border-radius: 5px;
		vertical-align: text-bottom;
		margin-right: 8px;
		margin-bottom: 4px;
	}
}
.card {
	height: 160px;
	border-radius: 4px;
	margin: 0 10px;
	overflow: hidden;
	.leftContent {
		margin: 36px 0 0 20px;
		& > div:nth-child(1) {
			color: #fff;
			opacity: 0.6;
			font-size: 14px;
		}
		& > div:nth-child(2) {
			font-family: fangsong;
			color: #fff;
			font-size: 28px;
			font-weight: bold;
			margin: 15px 0;
		}
		& > div:nth-child(3) {
			color: #fff;
			opacity: 0.6;
			font-size: 12px;
		}
	}
}
.leftCard {
	margin: 0 10px;
	> div {
		height: 100px;
		background: #ecf5ff;
		border-radius: 4px;
		margin-bottom: 10px;
	}
	.itemContent {
		overflow: hidden;
		& > div:nth-child(1) {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.5);
			margin: 30px 0 10px 30px;
		}
		& > div:nth-child(2) {
			font-family: fangsong;
			color: #fe5c32;
			margin: 0 30px;
			font-size: 26px;
			font-weight: bold;
		}
	}
}
.companyPie {
	height: 400px;
	width: 100%;
	margin-bottom: 20px;
}
.companyPieItem {
	height: 300px;
	width: 100%;
	margin-bottom: 20px;
}
#rightGraph1,
#rightGraph2,
#rightGraph3 {
	height: 210px;
	width: 100%;
}
</style>
